<template>
  <div class="result-table">
    <div class="setting-nav-title">{{ $t("userRight") }}</div>
    <div class="setting-tool">
      <div class="searchInput pull-left" v-permission="'Permission.Permission'">
        <el-input
          v-model="searchUserAuthString"
          :placeholder="$t('roleName')"
          clearable
          @keyup.enter.native="searchUserAuth"
        >
          <el-button slot="append" icon="el-icon-search" v-on:click="searchUserAuth"> </el-button>
        </el-input>
      </div>
      <el-button
        class="confirm dialog-btn fl-right"
        type="primary"
        @click="addRole"
        v-permission="'Permission.PermissionAdd'"
      >
        {{ $t("newRight") }}
      </el-button>
    </div>
    <el-table :data="tableData" v-loading="loading" @row-click="toDetail">
      <el-table-column prop="name" :label="$t('roleName')">
        <template slot-scope="scope">
          {{ formatRoleName(scope.row.roleName) }}
        </template>
      </el-table-column>
      <el-table-column prop="right" :label="$t('rightAbility')">
        <template slot-scope="scope">
          <div>
            <!-- {{ $t(`permission.${scope.row.displayPermission}`) }} -->
            {{ formatPermission(scope.row.displayPermission) }}
            ({{ $t("meter") }} {{ scope.row.permissionCount }})
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="scope">
          <svg-icon
            icon-class="edit"
            v-permission="'Permission.PermissionEdit'"
            v-if="IsBuiltInRole(scope.row.roleName)"
            class-name="handle-icon"
            @click.native.stop="edit(scope.row)"
          ></svg-icon>
          <svg-icon icon-class="expand" class-name="handle-icon" @click.native="toDetail(scope.row)"></svg-icon>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
      class="common-pagination"
    >
    </el-pagination>

    <side-layer :show-side-layer.sync="formDrawer" :wrapperClosable="false" @close="dialogClose">
      <div>
        <div class="setting-nav-title">{{ formType === "add" ? $t("newRightGroup") : $t("rightGroupEdit") }}</div>
        <el-form :model="form" ref="form" :rules="formRules" label-position="left">
          <el-form-item :label="$t('roleName')" prop="roleName">
            <el-input v-model="form.roleName"></el-input>
          </el-form-item>
        </el-form>
        <el-tree
          ref="addTree"
          :data="permissionListShow"
          show-checkbox
          node-key="permissionId"
          :default-expand-all="true"
          :render-content="renderContent"
        >
        </el-tree>

        <div>
          <el-button class="cancel" v-on:click="formDrawer = false">
            {{ $t("cancel") }}
          </el-button>
          <el-button
            class="confirm dialog-btn fl-right"
            type="primary"
            v-on:click="saveAddRole"
            :loading="submitLoading"
            :disabled="submitLoading"
          >
            {{ $t("submit") }}
          </el-button>
        </div>
      </div>
    </side-layer>

    <side-layer :show-side-layer.sync="detailDrawer">
      <div>
        <div class="setting-nav-title">{{ $t("roleDetail") }}</div>

        <div class="name-value" style="margin-bottom: 20px;">
          <table cellspacing="0" cellpadding="0" border="0">
            <tr v-for="(item, index) in roleDetails.roleGroupList" :key="item + index">
              <td class="name">
                {{ formatPermissionName(item.roleGroupName) }}
              </td>
              <td class="value">
                <div v-for="(value, index) in item.permissionList" :key="value + index">
                  {{ formatPermissionName(value) }}
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div style="margin-top: 16px;">
          <el-button
            class="delete"
            v-permission="'Permission.PermissionDelete'"
            v-if="IsBuiltInRole(roleDetails.roleName)"
            @click="removeDialog = true"
            >{{ $t("delete") }}</el-button
          >
        </div>
      </div>
    </side-layer>

    <modal-dialog :show-dialog.sync="removeDialog" :dialog-title="$t('rightGroupDel')">
      <div class="explain" style="text-align: center;">
        {{ roleDetails.roleName }}
      </div>
      <template v-slot:footer>
        <el-button class="cancel" @click="removeDialog = false"> {{ $t("cancel") }} </el-button>
        <el-button class="delete" @click="removeRole" :loading="removeRoleLoading" :disabled="removeRoleLoading">
          {{ $t("delete") }}
        </el-button>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import { roleList, rolePermissionList, addRolePermission, removeRole, modifyRolePermission } from "api/permission";
import {
  getAdminUserList,
  createAdminUser,
  deleteAdminUser,
  modifyAdminUser,
  getAdminUserRoleListWithPermission,
  getAllAdminUserRoleList,
  createAdminUserRole,
  modifyAdminUserRole,
  deleteAdminUserRole
} from "api/subAdmin";
import { checkIsNull } from "utils";
export default {
  mixins: [pagination],
  data() {
    return {
      formRules: {
        roleName: { required: true, message: this.$t("roleNameIsNull"), trigger: "blur" }
      },
      tableData: [],
      loading: false,
      formType: "",
      formDrawer: false,
      detailDrawer: false,
      removeDialog: false,
      removeRoleLoading: false,
      submitLoading: false,
      form: {
        roleName: "",
        permissionList: []
      },
      roleDetails: {},
      searchUserAuthString: "",
      roleGroupListShow: [],
      permissionListShow: []
    };
  },
  methods: {
    dialogClose() {
      this.$refs.form.resetFields();
    },
    IsBuiltInRole(roleName) {
      if (roleName == "SuperAdmin" || roleName == "Administrator" || roleName == "Manager" || roleName == "User") {
        return false;
      } else return true;
    },
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = pageIndex;
      let RoleName = this.searchUserAuthString;
      if (checkIsNull(RoleName) || RoleName == "") {
        getAdminUserRoleListWithPermission({
          PageSize: pageSize,
          PageIndex: pageIndex
        }).then(response => {
          this.loading = false;
          if (response.resultStatus === "Success" && response.statusCode === 200) {
            this.tableData = response.result;
            this.total = response.totalCount;
          }
        });
      } else {
        getAdminUserRoleListWithPermission({
          RoleName: RoleName,
          PageSize: pageSize,
          PageIndex: pageIndex
        }).then(response => {
          this.loading = false;
          if (response.resultStatus === "Success" && response.statusCode === 200) {
            this.tableData = response.result;
            this.total = response.totalCount;
          }
        });
      }
    },
    removeRole() {
      this.removeRoleLoading = true;
      deleteAdminUserRole({ roleId: this.roleDetails.roleId }).then(response => {
        this.removeDialog = false;
        this.detailDrawer = false;
        this.removeRoleLoading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.$message.success(this.$t("success"));
          if (Math.ceil((this.total - 1) / this.currentPageSize) >= this.currentPage) {
            this.getList(this.currentPage);
          } else {
            this.getList(this.currentPage - 1);
          }
        } else {
          this.$message.error(this.$t("fail"));
        }
      });
    },
    getPermissionList() {
      getAllAdminUserRoleList().then(response => {
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          response.result.map(value => {
            value.children = value.permissionList;
          });
          this.permissionListShow = response.result;
        }
      });
    },
    edit(data) {
      let checkedKeys = [];
      let permissionList = [];
      let roleGroupList = this.permissionListShow;
      roleGroupList.map(value => {
        value.permissionList.map(item => {
          permissionList.push(item);
        });
      });
      if (data.roleGroupList) {
        data.roleGroupList.map(item => {
          item.permissionList.map(value => {
            let permission = permissionList.find(m => m.permissionName == value);
            checkedKeys.push(permission.permissionId);
          });
        });
      }
      this.form = {
        roleId: data.roleId,
        roleName: this.formatRoleName(data.roleName)
      };
      this.$nextTick(() => {
        this.$refs.addTree.setCheckedKeys(checkedKeys, true);
      });
      this.formType = "edit";
      this.formDrawer = true;
    },
    addRole() {
      this.form = {
        roleName: ""
      };
      this.$nextTick(() => {
        this.$refs.addTree.setCheckedKeys([]);
      });
      this.formType = "add";
      this.formDrawer = true;
    },
    saveAddRole() {
      let permissionStrList = this.$refs.addTree.getCheckedNodes().filter(item => {
        return !!item;
      });
      let permissionList = permissionStrList
        .map(item => {
          return item.permissionName;
        })
        .filter(item => {
          return !!item;
        });
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (permissionStrList.length == 0) {
            this.$message.error(this.$t("nonePermissionSelected"));
            return;
          }
          let data = {
            roleName: this.form.roleName,
            permissionList: permissionList
          };
          this.submitLoading = true;
          //新增
          if (this.formType == "add") {
            createAdminUserRole(data).then(response => {
              if (response.resultStatus === "Success" && response.statusCode === 200) {
                this.$message.success(this.$t("success"));
                this.formDrawer = false;
                this.getList();
              } else {
                this.$message.error(this.$t("fail"));
              }
              this.submitLoading = false;
            });
          }
          //编辑
          else {
            data.roleId = this.form.roleId;
            modifyAdminUserRole(data).then(response => {
              if (response.resultStatus === "Success" && response.statusCode === 200) {
                this.$message.success(this.$t("success"));
                this.formDrawer = false;
                this.getList();
              } else {
                this.$message.error(this.$t("fail"));
              }
              this.submitLoading = false;
            });
          }
        } else {
          this.submitLoading = false;
          return false;
        }
      });
    },
    formatPermissionName(permissionName) {
      let formatPermissionName = "";
      switch (permissionName) {
        case "PermissionGroup":
          formatPermissionName = this.$t("PermissionGroup");
          break;
        case "Permission":
          formatPermissionName = this.$t("Permission");
          break;
        case "PermissionAdd":
          formatPermissionName = this.$t("PermissionAdd");
          break;
        case "PermissionDelete":
          formatPermissionName = this.$t("PermissionDelete");
          break;
        case "PermissionEdit":
          formatPermissionName = this.$t("PermissionEdit");
          break;
        case "RecordGroup":
          formatPermissionName = this.$t("RecordGroup");
          break;
        case "Record":
          formatPermissionName = this.$t("Record");
          break;
        case "RecordDownload":
          formatPermissionName = this.$t("RecordDownload");
          break;
        case "RefundGroup":
          formatPermissionName = this.$t("RefundGroup");
          break;
        case "Refund":
          formatPermissionName = this.$t("Refund");
          break;
        case "SettleGroup":
          formatPermissionName = this.$t("SettleGroup");
          break;
        case "Settle":
          formatPermissionName = this.$t("Settle");
          break;
        case "SettleDownload":
          formatPermissionName = this.$t("SettleDownload");
          break;
        case "ShopGroup":
          formatPermissionName = this.$t("ShopGroup");
          break;
        case "Shop":
          formatPermissionName = this.$t("Shop");
          break;
        case "StatGroup":
          formatPermissionName = this.$t("StatGroup");
          break;
        case "Stat":
          formatPermissionName = this.$t("Stat");
          break;
        case "StatDownload":
          formatPermissionName = this.$t("StatDownload");
          break;
        case "UserGroup":
          formatPermissionName = this.$t("UserGroup");
          break;
        case "User":
          formatPermissionName = this.$t("User");
          break;
        case "UserAdd":
          formatPermissionName = this.$t("UserAdd");
          break;
        case "UserDelete":
          formatPermissionName = this.$t("UserDelete");
          break;
        case "UserEdit":
          formatPermissionName = this.$t("UserEdit");
          break;
        case "MessageGroup":
          formatPermissionName = this.$t("MessageGroup");
          break;
        case "Message":
          formatPermissionName = this.$t("Message");
          break;
        case "MessageAdd":
          formatPermissionName = this.$t("MessageAdd");
          break;
        case "MessageDelete":
          formatPermissionName = this.$t("MessageDelete");
          break;
        case "MessageEdit":
          formatPermissionName = this.$t("MessageEdit");
          break;
        case "DataCompassGroup":
          formatPermissionName = this.$t("DataCompass");
          break;
        case "DataCompass":
          formatPermissionName = this.$t("DataCompass");
          break;
        case "PointsReport":
          formatPermissionName = this.$t("PointsReport");
          break;
        case "EntPointsReport":
          formatPermissionName = this.$t("EntPointsReport");
          break;
        default:
          formatPermissionName = this.$t(`permission.${permissionName}`);
          break;
      }
      return formatPermissionName;
    },
    formatPermission(permissionName) {
      let formatPermission = "";
      switch (permissionName) {
        case "allpermissions":
          formatPermission = this.$t("allpermission");
          break;
        case "nopermission":
          formatPermission = this.$t("nopermission");
          break;
        case "Record":
          formatPermission = this.$t("tradeSearch");
          break;
        case "RecordDownload":
          formatPermission = this.$t("tradeDownload");
          break;
        case "RecordEdite":
          formatPermission = this.$t("commentEdit");
          break;
        case "Settle":
          formatPermission = this.$t("receiptSearch");
          break;
        case "SettleDownload":
          formatPermission = this.$t("receiptDownload");
          break;
        case "Shop":
          formatPermission = this.$t("storeManagement");
          break;
        case "User":
          formatPermission = this.$t("userSearch");
          break;
        case "UserAdd":
          formatPermission = this.$t("newUser");
          break;
        case "UserEdit":
          formatPermission = this.$t("userEdit");
          break;
        case "UserDelete":
          formatPermission = this.$t("userDel");
          break;
        case "Permission":
          formatPermission = this.$t("rightGroupSearch");
          break;
        case "PermissionAdd":
          formatPermission = this.$t("newRightGroup");
          break;
        case "PermissionEdit":
          formatPermission = this.$t("rightGroupEdit");
          break;
        case "PermissionDelete":
          formatPermission = this.$t("rightGroupDel");
          break;
        case "Refund":
          formatPermission = this.$t("refund");
          break;
        case "Stat":
          formatPermission = this.$t("statistic");
          break;
        case "StatDownload":
          formatPermission = this.$t("statisticDownload");
          break;
        case "Message":
          formatPermission = this.$t("Message");
          break;
        case "MessageAdd":
          formatPermission = this.$t("MessageAdd");
          break;
        case "MessageEdit":
          formatPermission = this.$t("MessageEdit");
          break;
        case "MessageDelete":
          formatPermission = this.$t("MessageDelete");
          break;
        case "DataCompass":
          formatPermission = this.$t("DataCompass");
          break;
        case "PointsReport":
          formatPermission = this.$t("PointsReport");
          break;
        case "EntPointsReport":
          formatPermission = this.$t("EntPointsReport");
          break;
        default:
          formatPermission = this.$t(`permission.${permissionName}`);
          break;
      }
      return formatPermission;
    },
    formatRoleName(roleName) {
      let formatRoleName = "";
      switch (roleName) {
        case "SuperAdmin":
          formatRoleName = this.$t("superAdmin");
          break;
        case "Administrator":
          formatRoleName = this.$t("administrator");
          break;
        case "Manager":
          formatRoleName = this.$t("manager");
          break;
        case "User":
          formatRoleName = this.$t("userRole");
          break;
        default:
          formatRoleName = this.$t(`${roleName}`);
          break;
      }
      return formatRoleName;
    },

    toDetail(data) {
      this.roleDetails = data;
      this.detailDrawer = true;
    },
    searchUserAuth() {
      this.getList();
    },
    renderContent(h, { level, data }) {
      return (
        <div>
          {data.roleGroupName
            ? this.$t(`permission.${data.roleGroupName}`)
            : this.$t(`permission.${data.permissionName}`)}
        </div>
      );
    }
  },

  mounted() {
    this.searchUserAuthString = "";
    this.getPermissionList();
    this.getList();
  }
};
</script>

<style scoped>
.fl-right {
  float: right;
}
.el-tree {
  margin-bottom: 20px;
}
</style>
